<template>
    <div id="goods">
       
        <router-view></router-view>
        <div class="xian"></div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            type: 1,
        }
    },
    created(){},
    mounted(){},
    watch: {},
    methods: {}
}
</script>

<style lang="scss" scoped>
#goods{
    .goods_page_nav{
        margin-top: 10px;
        border-bottom: 2px solid #f80;
        .goods_nav_in{
            .right_text{
                line-height: 40px;
                color: #f80;
                &:hover{
                    cursor: pointer;
                    color: #666;
                }
            }
            ul{
                li{
                    line-height: 40px;
                    width: 150px;
                    text-align: center;
                    &:hover{
                        cursor: pointer;
                    }
                }
                .router-link-exact-active{
                    background: #f80;
                    color: #fff;
                }
                .red{
                    background: #f80;
                    color: #fff;
                }
            }
        }
    }
    .mbx{
        line-height: 60px;
        font-size: 14px;
        color: #666;
    }
    .xian{
        height: 5px;
        background: #eee;
        margin-top: 30px;
        margin-bottom: 20px;
    }
}
</style>